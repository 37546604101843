.search-main
	position absolute
	width 100%
	left 0
	display flex
	justify-content center
	flex-direction column
	align-items center
	line-height 0
	top 0
	height 100%
	opacity 0
	transition .3s
	z-index -10
	visibility hidden
	.pad
		width 530px
		border 1px solid #fff
		position relative
		padding-left 56px
	input
		height 40px
		width 100%
		font-size var(--fz14)
		background none
		border none
	.btn-search
		position absolute
		left 0
		position absolute
		top 50%
		left 0
		transform translateY(-50%)
		text-align center
		padding-left 20px
		height 100%
		background none
		border none
		cursor pointer
		i
			font-size var(--fz18)
			color #fff
	.icon-x
		position absolute
		right 0
		top 50%
		transform translateY(-50%) translateX(100%)
		font-size var(--fz16)
		padding 20px
		cursor pointer
		transition .3s
		&:hover
			opacity .7
.transparent-hd
	#hd
		.btn-search
			transition .3s
			i
				color #fff
			&:hover
				opacity .7
		input
			color #fff	
		.icon-x
			color #fff
		::placeholder
			color #fff
		:-ms-input-placeholder
			color #fff
		:-webkit-input-placeholder
			color #fff
		.pad
			border-color #fff
/*******************************
	-------[=白色头部下的搜索]-------
********************************/
#hd
#hd.white-hd
	.btn-search
		i
			color m
	.pad
		border-color #DBE7F2
		border-color m
	input
		color #000
	.icon-x
		color #464646
		&:hover
			opacity 1
			color m
	::placeholder
		color #666
	:-ms-input-placeholder
		color #666
	:-webkit-input-placeholder
		color #666
.show-search
	.search-main
		opacity 1
		visibility visible
		z-index 10
	#hd
		.wrap
			opacity 0
			z-index -10
// 手机
.show-search
	display block
.m_header
	.search-main
		background-color #fff
		padding-left 40px
		flex-direction row
		.pad
			border-color #DBE7F2
			border-color m
			padding-left 64px
			flex 1
			width auto
		.icon-x
			position static
			color #333
			transform none
			padding 40px 30px
			font-size 20px
		input
			height 72px
		.btn-search
			i
				color m