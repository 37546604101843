@media screen and (max-width: 1520px)
	html
		font-size 88px
@media screen and (max-width: 1440px)
	html
		font-size 78px
@media screen and (max-width: 1100px)
	html
		font-size 60px
@media screen and (max-width: 760px)
	html
		font-size 50px
@media screen and (max-width: 320px)
	html
		font-size 40px