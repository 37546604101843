.product-index
	padding-bottom 100px
	.wp	
		display flex
.pro-side
	flex 0 1 360px
	transform translateY(-70px)
	background-color #023A5B
	color #fff
	padding 40px
	height 100%
	h3
		color #fff
		font-size var(--fz16)
		margin-bottom 14px
	h4
		cursor pointer
		color #fff
		font-size var(--fz16)
		display flex
		justify-content space-between
.pro-main
	flex 1
	padding-left 50px
.sub-pro
	display none
	a
		display block
		color #fff
		font-size var(--fz14)
		margin 16px 0
		opacity .5
		transition .3s
		&.active
		&:hover
			opacity 1
		&:last-child
			margin-bottom 16px
.pro-menu
	h4
		padding 20px 0
		border-bottom 1px solid rgba(255,255,255,.3)
		line-height 1
.m-pro-select
	background-color #023A5B
	padding-left 40px
	padding-right 40px
	padding-top 30px
	padding-bottom 30px
	.inp-select
		flex 1
	select
		border 1px solid #fff
		height 70px
		color #fff
		padding-left 20px
		padding-right 20px
		option
			color #333
	.inp-select:first-child
		margin-right 10px
	.inp-select:last-child
		margin-left 10px
	h3
		font-size 14px
		color #fff
		margin-bottom 20px
.select-row
	display flex
@media screen and (max-width: 1400px)
	.list-culture .con
		padding-left 30px
		padding-right 30px
+padH()
	.list-pro
		li
			flex 0 1 50%
+mb()
	.product-index
		.wp
			display block
		.pro-main
			padding-left 0
	.pro-side
		display none
	.list-pro
		display block
		.img
			height 630px
			line-height 630px
		.txt
			padding-bottom 30px
			h3
				font-size 18px
			i
				opacity 1
				transform none