.modal-job-detail
	width 90%
	max-width 1200px
	background-color #fff
	overflow hidden
	border-radius 20px
	padding 50px
a.close-modal
	position absolute
	top 30px
	right 30px
	i
		font-size 30px
		font-weight bold
		color rgba(0,0,0,.5)
		transition .3s
		&:hover
			color m
.modal-job-detail
	.hd
		text-align center
		margin-bottom 30px
	h3
		font-size var(--fz30)
		font-weight bold
	span
		margin 0 20px
	em
		color m
	.bd
		width 90%
		margin 0 auto
		h4
			font-size var(--fz20)
			font-weight bold
			margin-bottom 6px
		.text
			margin-bottom 30px