m = #005BA9
g = #C8C8C8
li = #EEEEEE
red = #cd2c24
h1,h2,h3,h4,h5,h6
	color #333
	font-weight normal
a
	color #1e1e1e
	transition color .3s
a:hover
	color m
.bg-gray
	background-color #F5F7F9
.blue
	color m !important
