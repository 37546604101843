
.page 
	margin-top 60px
	text-align center
	span
	a 
		display inline-block
		min-width 36px
		height 36px
		line-height 34px
		text-align center
		transition 0.3s
		background-color #E5E8EF
		font-size var(--fz14)
		zoom 1
		vertical-align middle
		margin 0 2px
		cursor pointer
		color #333
	.prev
	.next
		i
			transition .3s
		&:hover
			i
				color #fff
	em
		margin-left 10px
		vertical-align middle
	span
		padding 0 4px
		vertical-align middle
		margin 0 2px
.page a.on, .page a:hover, .page a.current 
	background-color m
	color #fff
			
.page .go-page
	border 1px solid m
	display inline-block
	min-width 36px
	height 36px
	line-height 34px
	text-align center
	padding 0 10px
	margin-left 10px
	color m
	cursor pointer
	transition .3s
	background none
	&:hover
		background-color m
		color #fff
		border-color m
.page
	.icon-prev-page
	.icon-next-page
		color #333
.quick-jumper
	vertical-align middle
	color: #000
	font-size var(--fz14)
	display inline-block
	margin-left 30px
.quick-jumper input
	height 36px
	line-height 34px
	width 60px
	padding-left 10px
	border 1px solid #eee
	margin 0 9px
@media screen and (max-width: 760px)
	.page
		margin-top 60px
		a
		span
			min-width 60px
			height 60px
			line-height 60px
	.page-left .page
		text-align center
	.page
		em
			display none
	.ant-pagination-custom
		.pagination-count
			display none
	.quick-jumper
		display none
	.go-page
		display none !important