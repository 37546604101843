.list-recruit
	.item
		margin-bottom 20px
		background-color #F4F6F8
	.hd
		padding 24px 50px
		display flex
		cursor pointer
		align-items center
		transition .3s
		position relative
		&:hover
			background-color #E5E8EF
		h3
			font-size var(--fz20)
			flex 0 1 470px
			flex 0 1 33%
			font-weight bold
			padding-right 40px
		.loc
			width 110px
		.edu
			width 170px
		.ico
			position absolute
			top 50%
			right 0
			transform translateY(-50%)
			padding-right inherit
		i
			width 28px
			height 28px
			line-height 26px
			display inline-block
			border 1px solid m
			color m
			cursor pointer
			border-radius 50%
			text-align center
	.meta
		flex 1
		display flex
		align-items center
	.bd
		display none
	.pad
		padding 40px 50px
		padding-bottom 60px
		display flex
		justify-content space-between
		border-top 1px solid #EDEEEE
		h4
			font-size var(--fz16)
			font-weight bold
			margin-bottom 20px
.job-desc
	flex 0 0 60%
	.line
		margin-bottom 30px
		margin-top 30px
		border-top 1px solid #EDEEEE
.job-contact
	flex 0 1 auto
	padding-left 30px
	margin-left auto
	i
		margin-right 8px
	.more-arrow
		margin-top 50px
+padW()
	.list-recruit
		.item
			.hd
				display block
				padding-top 40px
				padding-bottom 40px
				.edu
					width auto
					margin-right 40px
				.loc
					width auto
					margin-right 40px
				h3
					font-size 16px
			.meta
				margin-top 10px
		.pad
			display block
.file-select
	input
		width 0
		height 0
		position absolute
		opacity 0
.file-select
	display block
.file-row
	margin-top 20px
	display none
	em:hover
		color #f00
	u
		text-decoration none
		color m
.show-submit
	.file-row
		display block
	.file-select
		display none
	.btn-submit-resume
		display inline-block
.file-row
	padding-right 100px
	position relative
	p
		display inline-block
	em
		margin-left 10px
		cursor pointer
.btn-submit-resume
	display none
+mb()
	.list-recruit
		.pad
		.hd
			padding-left 30px
			padding-right 30px
		.hd
			i
				width 36px
				height 36px
				line-height 34px
	.job-contact
		padding-left 0
		padding-top 50px
		margin-top 50px
		border-top 1px solid #edeeee
		.pad
			h4
				margin-bottom 25px
	.job-desc
		.line
			opacity 0
