.more
	text-transform uppercase
	display inline-block
	line-height 1
	color m
	transition .3s
	&:hover
		opacity .8
	i
		vertical-align middle
		position relative
	span
		vertical-align middle
		margin-left 10px
.more-white
	color #fff
	border-color #fff
	i:after
		background-color rgba(255,255,255,.2)
	&:hover
		border-color #CD2C24
.more-arrow
	display inline-block
	padding 12px 40px
	transition .3s
	border none
	cursor pointer
	i
		margin-left 6px
		font-size var(--fz14)
		vertical-align middle
		&:before
			display inline-block
	span
		vertical-align middle
.more-arrow-white
	background-color #fff
	color m
	&:hover
		background-color m
		color #fff
.i-row6
	.more-arrow-white
		&:hover
			color m
			background-color #fff
.more-arrow-blue
	background-color m
	color #fff
	&:hover
		background-color #0267bd
		color #fff
		// opacity .8
.more-arrow
	&:hover
		.iconfont:before
			animation moveRightIn 1s 1
.more-arrow-link
	display inline-flex
	align-items center
	transition color .3s
	color #fff
	i
		margin-left 8px
		font-size var(--fz14)
		vertical-align middle
		transition transform .3s
		line-height 1
	em
		vertical-align middle
	&:hover
		i
			transform: translateX(6px)
.more-arrow-line
	border 1px solid m
	color m
.row-code
	button
		width 220px
		font-size var(--fz16)
+mb()
	.more
		display inline-block
	.more-arrow
		padding 16px 60px
		span
			margin-right 6px
	.btn-full
		width 100%
		padding-top 0
		padding-bottom 0
		text-align center
		height: 80px;
		line-height: 80px;
	button.btn-full
		padding-top 0
		padding-bottom 0
	.more-arrow-line
		line-height 78px
	