.row-training1
	padding-top 80px
	padding-bottom 50px
.training-row .t3
.row-training2 .t3
.row-training3 .t3
	margin-bottom 30px
.row-training4 .t3
	margin-bottom 36px
.row-training5 .t3
	margin-bottom 20px
.training-row
	display flex
	align-items center
	margin-bottom 50px
	.img
		flex 0 1 49%
		height 420px
	.txt
		flex 1
	&:nth-child(2n)
		.img
			order 1
		.txt
			padding-right 95px
	&:nth-child(2n+1)
		.txt
			padding-left 95px
.row-training2
	padding 100px 0
	background url("../images/bg-college.jpg") no-repeat left top
	cover()
	.t3
	.text
		color #fff
	.text
		width 43%
.list-college
	position relative
	margin-top 4px
	margin-bottom 4px
	.wp
		display flex
		justify-content space-between
		position relative
		z-index 10
	.item
		flex 0 1 290px
		padding-top 70px
		padding-bottom 70px
	.t4
		color #fff
	.text
		color #fff
.bg-college
	display flex
	position absolute
	left 0
	top 0
	left 0
	right 0
	height 100%
	li
		flex 0 1 33.3333%
		border-right 0.04rem solid #fff
		&:last-child
			border-right none
	li:nth-child(1)
		background #023A55 url("../images/dt1.png") no-repeat right top
		background-size 512px
	li:nth-child(2)
		background #0C5BA6 url("../images/dt2.png") no-repeat left top
		background-size 100%
	li:nth-child(3)
		background #17A9E7 url("../images/dt3.png") no-repeat left top
		background-size 100%
.row-training3
	background url("../images/bg-talent.jpg") no-repeat left top
	cover()
	padding-top 100px
	padding-bottom 110px
	.wp
		display flex
	.txt
		flex 0 1 50%
		margin-left auto
	.t3
	.text
		color #003A5D
.row-training4
	padding 100px 0
.list-trainee
	display flex
	margin 0 -4px
	flex-wrap wrap
	li
		flex 0 1 20%
		padding 0 4px
	.pad
		min-height 300px
		height 100%
		background-color #F5F7F9
		padding-left 30px
		padding-right 30px
		text-align center
		padding-top 70px
		padding-bottom 30px
	i
		font-size var(--ic60)
		line-height 1
	p
		margin-top 36px
		font-size var(--fz20)
.row-training5
	padding 80px 0
	background url("../images/bg-more-job.jpg") no-repeat left top
	cover()
	text-align center
	.t3
	p
		color #fff
	.text
		margin-bottom 36px	
+mb()
	.training-row
		display block
		&:nth-child(2n+1) .txt
			padding-left 0
		&:nth-child(2n) .txt
			padding-right 0
		.txt
			padding-top 50px
			padding-bottom 50px
		.img
			height 378px
	.row-training2 .text
		width auto
	.row-training2
		background none
		padding-top 0
		background-color #F4F6F8
		padding-bottom 0
		.t3
			display none
		.wp
			.text
				color #666
				padding-top 50px
				padding-bottom 50px
	.m-row-training2
		background-image url("../images/bg-college.jpg")
		cover()
		padding-top 280px
		.t3
			padding-bottom 44px
			display block
			margin-bottom 0
	.list-college
		padding 40px 0
		.wp
			display block
		.item
			margin-bottom 10px
			padding-left 30px
			padding-right 30px
		.item:nth-child(1)
			background #023A55 url("../images/dt1.png") no-repeat right top
			background-size 100%
		.item:nth-child(2)
			background #0C5BA6 url("../images/dt2.png") no-repeat left top
			background-size 100%
		.item:nth-child(3)
			background #17A9E7 url("../images/dt3.png") no-repeat left top
			background-size 100%
		.t4
			br
				display none
	.bg-college
		display none

	.row-training3
		background none
		padding-top 0
		background-color #F4F6F8
		padding-bottom 0
		.t3
			display none
			display none
		.text
			color #666
			padding-top 50px
			padding-bottom 50px
		.txt
			margin-left 0
		.wp
			display block
	.m-row-training3
		background-image url("../images/m-bg-talent.jpg")
		cover()
		background-position 40% 50%
		padding-top 280px
		.t3
			padding-bottom 44px
			display block
			margin-bottom 0
	.list-trainee
		margin-top 68px
		display grid
		grid-template-columns repeat(2, 1fr) 
		grid-auto-rows 1fr
		&:before
			display block
			content ''
			width 0
			grid-row 1/1
			grid-column 1/1
			padding-bottom 100%
		> *:first-child
			display block
			grid-row 1/1
			grid-column 1/1
		li
			flex 0 1 50%
			padding-bottom 8px
		.pad
			min-height 1px
			padding-top 30%
	.row-training5
		padding-top 100px
		padding-bottom 100px
		background-position 41% 50%
		.text
			margin-bottom 44px