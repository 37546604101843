.inner-bar
	border-bottom 1px solid #eee
	background-color #fff
.nav-inner
	display flex
	a
		margin-right 70px
		position relative
		height: 55px;
		line-height: 55px;
		display block
		&:hover
			color #03579F
		&:before
			content ''
			position absolute
			left 0
			right 0
			bottom -1px
			border-top 2px solid #03579F
			display none
	li
		position relative
	li.on
		color #03579F
		a:before
			display block
+padW()
	.nav-inner
		overflow hidden
		overflow-x auto
		li
			flex 0 0 auto
		a
			height 80px
			line-height 80px
			&:before
				bottom 0