cf()
	zoom 1
	&:after
	&:before
		content ''
		display block
	&:after
		clear both
.img-ovh
	overflow hidden
a
	cursor pointer
i.iconfont
	font-size inherit
.cover-bg
	background-size cover
	background-position 50% 50%
	background-repeat no-repeat
cover()
	background-size cover
	background-position 50% 50%
	background-repeat no-repeat
ell()
	overflow:hidden;
	white-space:nowrap;
	text-overflow:ellipsis;
.bg
.img
	transform: translateZ(0) scale(1.0, 1.0);
.bg
.img
	background-size cover
	background-position 50% 50%
	background-repeat no-repeat
	transition .4s
	transform: translateZ(0) scale(1.0, 1.0);
.img-normal
	&:hover
		transform: scale(1);
.img-contain
	background-size contain
	background-position 50% 50%
	background-repeat no-repeat
	transition .4s
	transform: translateZ(0) scale(1.0, 1.0);
.img-hover
	background-size cover
	background-position 50% 50%
	background-repeat no-repeat
	overflow hidden
	transition .4s
	transform: translateZ(0) scale(1.0, 1.0);
.img-hover:hover
	.img
	.bg
	.img-contain
		transform: scale(1.04);
imgHover()
	&:hover
		.img
		.bg
		.img-contain
			transform: scale(1.04);
line()
	&:after
		content ''
		position absolute
		left 0
		transition .3s
		width 0
		bottom 0
	&:hover:after
		width 100%
.img
	cover()
.tc
	text-align center
img
	max-width 100%
	vertical-align bottom
ell()
	overflow:hidden;
	white-space:nowrap;
	text-overflow:ellipsis;
::selection
	background-color m
	color #fff
body
	font-size 16px
	line-height 1.6
	background-color #fff
body
html
	overflow-x hidden
.home
	background-color #fff
html
	font-size 100px
.text
	color #666666
	line-height 1.75
	font-size var(--fz16)
.text-white
	color #fff
.text-big
	font-size var(--fz18)
.text-margin
	p
		margin-bottom 1em
.img-ovh
	position absolute
	left 0
	top 0
	width 100%
	height 100%
	.bg
		width 100%
		height 100%
.btn-tc
	text-align center
.m-show
	display none
+mb()
	.m-show
		display block
	.m-hide
		display none