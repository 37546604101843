.ban-inner
	height 500px
	cover()
	.wp
		height 100%
	.cell
		display flex
		flex-direction column
		justify-content center
		height 100%
		color #fff
	h3
		color #fff
		font-size var(--fz42)
		margin-bottom 14px
	.text
		color #fff
		width 500px
+mb()
	.ban-inner
		height 600px
		.text
			width auto
	