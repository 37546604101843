.search-result
	.hd	
		text-align center
		margin-bottom 50px
	.bd
		h4
			font-size var(--fz20)
			margin-bottom 20px
			span
				color m
.list-result
	li
		padding 30px 0
		border-bottom 1px solid #f9fafb
		&:first-child
			border-top 1px solid #f9fafb
	h3
		font-size var(--fz24)
		margin-bottom 6px
		transition .3s
		span
			color m
	p
		color #666666
	a:hover
		h3
			color m
.search-result
	.pad
		position relative
		width 800px
		margin 0 auto
/*******************************
	-------[=搜索结果页面]-------
********************************/
.search-result
	.form-ele
		height 60px
		font-size var(--fz16)
	.btn-search
		position absolute
		right 0
		padding-right 20px
		background none
		border none
		top 50%
		transform translateY(-50%)
		cursor pointer
		height 100%
		transition .3s
		&:hover
			color m
		i
			font-size var(--fz18)
			color m
+padW()
	display block
	.search-result
		.form-ele
			height 80px
			padding-left 30px
			padding-right 30px
		.t1
			margin-bottom 40px
		.pad
			width 100%
		.btn-search
			padding-right 30px
		li
			border-color #ECECEC !important
			padding-top 50px
			padding-bottom 50px
		.bd
			h4
				margin-bottom 30px