:root{
	--fz64: 64px;//banner 大字
	
	--fz60: 60px;//数字
	
	--fz48: 48px;
	--fz44: 44px; //日期
	--fz42: 42px; //t1
	--fz36: 36px; //t3
	--fz32: 32px;
	--fz30: 30px;
	
	
	--fz28: 28px; 
	--fz28-m: 28px; //手机
	
	--fz24: 24px;
	--fz22: 22px;
	--fz21: 21px;
	--fz20: 20px;
	--fz18: 18px;
	--fz16: 16px;
	--fz14: 14px;
	
	--nav: 16px;
	
	
	--ic12: 12px;
	--ic50: 50px;
	--ic48: 48px;
	--ic60: 60px;
}

@media screen and (max-width: 1600px){
	body{
		font-size: 15px;
	}
	:root{
		--fz64: 50px;//banner 大字
		--fz60: 50px; //数字
		--fz48: 40px;
		--fz44: 38px; //日期
		--fz42: 34px;//t1
		--fz40: 34px;
		--fz36: 30px;
		--fz32: 28px;
		--fz28: 24px; 
		
		--fz30: 26px;
		--fz24: 20px;
		--fz22: 18px;
		--fz20: 18px;
		--nav: 15px;
		--fz21: 18px;
		--fz18: 16px;
		--fz16: 15px;
		
		--ic60: 50px;
		--ic50: 40px;
		--ic48: 40px;
	}
}
@media screen and (max-width: 1440px){
	body{
		font-size: 14px;
	}
	:root{
		--fz64: 46px;//banner 大字
		--fz60: 40px;//数字
		--fz48: 32px;
		--fz44: 32px; //日期
		--fz42: 30px;//t1
		--fz40: 28px;
		--fz36: 24px;//t3
		--fz32: 20px;
		--fz30: 22px;
		--fz28: 20px; 
		--fz24: 18px;
		--fz21: 16px;
		--fz20: 18px;
		--fz18: 15px;
		--nav: 15px;
		--fz16: 14px;
		--ic48: 36px;
	}
}

@media screen and (max-width: 1200px){
	:root{
		--fz64: 36px;//banner 大字
		--fz60: 36px;//数字
		--fz48: 24px;
		--fz36: 20px;//t3
		--fz42: 28px;//t1
		--fz20: 16px;
		--fz18: 14px;
		
		--fz21: 14px;
		
	}
}

@media screen and (max-width: 1100px){
	:root{
		--fz28-m: 20px; //手机
		--fz42: 22px;//t1
		--fz24: 16px;
		--fz20: 15px;
	}

}
@media screen and (max-width: 760px){
	
	:root{
		--fz60: 40px;//数字
		
		
		--fz42: 27px;//t1
		--fz36: 23px;
		--fz30: 18px;
		--fz24: 16px;
		--fz20: 14px;
		--fz28-m: 14px; //手机
		
		
		
		--fz18: 14px;
		--fz16: 13px;
		--ic60: 30px;
		
		--fz14: 12px;
	}
	
}


