#hd
	position fixed
	left 0
	width 100%
	top 0
	z-index 100
	transition .3s
	background-color #fff
	// box-shadow: 0px 4px 8px -2px rgba(0,0,0,.1);
	border-bottom 1px solid #eee
	.wrap
		display flex
		align-items center
		transition .3s
		width 90%
		margin 0 auto
.hd-mask
	content ''
	position fixed
	left 0
	right 0
	top 0
	height 100vh
	background-color rgba(0,0,0,.5)
	z-index -1
	pointer-events none
	opacity 0
	visibility hidden
	transition .3s
/*.nav
	li
		&.show-sub
			.sub-nav
				&:after
					z-index 0
					opacity 1
					visibility visible*/
body.show-sub
body.show-search
	.hd-mask
		z-index 81
		opacity 1
		visibility visible
.nav
	margin-left auto
	> li
		display inline-block
		margin-left 60px
		position relative
		height 80px
		line-height 80px
		transition .3s
		a
			font-size var(--nav)
			color #323232
			&:hover
				color m
	.pad
		position relative
		height 0
		overflow hidden
		z-index 10
		transition .4s ease-in-out
		a:first-child
			margin-top 30px
		a:last-child
			margin-bottom 45px
	.sub-nav
		position absolute
		left 0
		text-align center
		white-space nowrap
		left 50%
		transform translateX(-50%)
		top 100%
		margin-top 1PX
		background-color #fff
		z-index 10
		box-shadow: 0px 4px 8px -2px rgba(0,0,0,.1);
		transition background-color .3s
		&:after
			content ''
			position fixed
			position absolute
			left -2000px
			right -2000px
			height 100vh
			top -100px
			background-color rgba(0,0,0,.5)
			z-index -1
			opacity 0
			visibility hidden
			transition .3s
			pointer-events none
			display none
		&:before
			content ''
			position absolute
			left 0
			right 0
			left -6000px
			right -6000px
			background-color rgba(255,255,255,1)
			top 0
			bottom 0
			z-index 1
		a
			padding-bottom 0
			margin-left 0
			margin-right 0
			line-height 2.2
			color #666666
			display block
			transition .3s
			font-size var(--nav)
			&:hover
				color m

.hd-r
	position relative
	text-align right
	z-index 50
	margin-left auto
	display flex
	align-items center
	i
		transition .3s
		&:hover
			color m
.search-ico
	position relative
	margin-left 168px
	i
		cursor pointer
		font-size var(--fz18)
		transition .3s
		&:hover
			color m
.logo
	img
		height 24px
		transition .3s
	.white
		display none
	.dark
		display block
/*******************************
	-------[=透明头部]-------
********************************/
.transparent-hd 
	#hd
		background none
		box-shadow: 0 0 0 0 rgba(0,0,0,.1);
		border-color transparent
	.nav
		> li
			height 80px
			line-height 80px
	.sub-nav
		a
			color #fff
			&:hover
				color m
		&:before
			opacity 0
			transition .3s
			pointer-events none
	.search-ico
		i
			color #fff
			&:hover
				color m
	.nav
		a
			color #fff
		.sub-nav
			background none
			box-shadow none
	.logo
		margin-top 0
		img
			filter brightness(0) invert(1)
.transparent-hd
	.white-hd
		.sub-nav
			top 100%
			margin-top 1PX
			&:before
				opacity 1
.show-sub #hd
#hd.white-hd
	background-color #fff
	// box-shadow: 0px 4px 8px -2px rgba(0,0,0,.1);
	border-bottom 1px solid #eee
	.nav
		> li
			height 80px
			line-height 80px
		a
			color #323232
			&:hover
				color m
	.sub-nav
		top 100%
		margin-top 1PX
		&:before
			opacity 1
	.logo
		img
			filter none
	.search-ico
		&:after
			border-color #323231
		i
			color #323231
			&:hover
				color m

.header-holder
	height 80px
.transparent-hd
	.header-holder
		display none

/*******************************
	-------[=高亮当前菜单]-------
********************************/
#hd	.nav .active
	color m
body.show-sub #hd .nav .active
#hd.white-hd .nav .active
	color m
.m-nav-list
	.active
		color m
@media screen and (max-width: 1300px)
	.nav > li
		margin-left 40px
+padW()
	.header-holder
		height 120px
+mb()
	.hd-mask
		display none