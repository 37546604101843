.page-news
	padding 100px 0
.swiper-news-hot
	.item
		display flex
		background-color #fff
		height 430px
	h4
		font-size var(--fz24)
		hidden(2)
		margin-bottom 32px
	.img-hover
		flex 0 1 50%
		.img
			height 100%
	.txt
		flex 0 1 50%
		padding 70px
		display flex
		flex-direction column
		justify-content space-between
	.text
		hidden(3, 1.75)
	.fd
		display flex
		justify-content space-between
		align-items center
	em
		color #333
		font-size var(--fz20)
.swiper-news-hot
	.swiper-pagination-line
		margin-top 40px
.list-news-card
	display flex
	margin 0 -12px
	margin-top 60px
	flex-wrap wrap
	li
		flex 0 1 33.33333%
		padding 12px
	.img-hover
		height 278px
		.img
			height 100%
	.txt
		padding 28px 30px
		display flex
		flex-direction column
		justify-content space-between
	h4
		font-size var(--fz20)
		overflow hidden
		text-overflow ellipsis
		display -webkit-box
		-webkit-box-orient vertical
		-webkit-line-clamp 2
		height 3.2em
		transition color .3s
		margin-bottom 35px
	.meta
		display flex
		justify-content space-between
		s
			color #333
			text-decoration none
		span
			transition color .3s
	a
		display block
		background-color #fff
		transition background-color .3s
		&:hover
			background-color #E4E7EE
			h4
				color m
			span
				color m
+mb()
	.swiper-news-hot
		.item
			display block
			height auto
		.img-hover
			height 380px
		.txt
			padding 50px 30px
		.fd
			margin-top 80px
	.list-news-card
		display block
		.img-hover
			height 380px
		li
			padding-top 15px
			padding-bottom 15px
	.page-news
		padding-top 125px
	.list-news-card
		margin-top 80px