.article-detail
	padding 80px 0
	position relative
	.hd 
		padding-bottom 40px
		h1 
			font-size var(--fz36)
			margin-bottom 10px
	.meta
		color #636363
		font-size var(--fz20)
	.bd
		padding 10px 0 30px 0
		overflow hidden
		img
			margin 1em 0
		+mb()
			height auto !important
.article-detail
	.wp
		display flex
	.article-main
		flex 0 1 1000px
	.text
		p
			margin-bottom 1em
			margin-top 1em
.article-side
	text-align right
	margin-left auto
	line-height 1.1
	.more-arrow
		padding-right 0
		padding-left 0
		&:hover
			color m
			transform: translateX(-10px)
+padH()
	.article-detail
		.wp
			display block
	.article-side
		display flex
		justify-content space-between
		p:nth-child(1)
			order 1
		p:nth-child(2)
			order 3
		p:nth-child(3)
			order 2
+mb()
	.article-detail
		p
			font-size 13px !important
		span
			font-size 13px !important