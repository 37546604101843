body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
	margin: 0;
	padding: 0;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

fieldset,
img {
	border: 0;
}

ol,
ul {
	list-style: none;
}

caption,
th {
	text-align: left;
}

q:before,
q:after {
	content: '';
}

abbr,
acronym {
	border: 0;
}

.clear {
	clear: both;
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}

.c {
	clear: both;
}

.n,
em {
	font-weight: normal;
	font-style: normal;
}

.dn {
	display: none;
}

.fix {
	*zoom: 1;
}

.fix:after,
.fix:before {
	display: block;
	content: 'clear';
	height: 0;
	clear: both;
	overflow: hidden;
	visibility: hidden;
}

a {
	text-decoration: none;
}

a:hover {
	color: #ff6600;
}

input[type='input'] {
	border: none;
	-webkit-border-radius: none;
	border-radius: none;
}

button::-moz-focus-inner {
	border: 0;
}

:focus,
:active {
	outline: 0;
	/*border: 0;*/
}

select {
	-webkit-border-radius: 0;
	border-radius: 0;
	-webkit-appearance: none;
	appearance: none;
	/*background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='图层_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 348 200' style='enable-background:new 0 0 348 200;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23333333;%7D%0A%3C/style%3E%3Cpath class='st0' d='M100,147.9L17.7,52.1h164.5L100,147.9z'/%3E%3C/svg%3E%0A");*/
	background-color: transparent;
	background-position: right 50%;
	background-repeat: no-repeat;
	/*background-size: .20rem;*/
}
select option {
	background-color: none;
}

input::-webkit-input-placeholder {
	line-height: 1em;
}

input[type='submit'] {
	border: none;
	-webkit-border-radius: 0;
	border-radius: 0;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}

select {
	-webkit-border-radius: 0;
	border-radius: 0;
	border: 0;
	font-family: 'Arial', 'PingFang SC', 'Microsoft YaHei', 'Microsoft YaHei UI',
		'Source Han Sans CN', 'Source Han Serif CN', 'SimSun', 'SimHei', 'Arial';
}

::-webkit-input-placeholder {
	font-family: 'Arial', 'PingFang SC', 'Microsoft YaHei', 'Microsoft YaHei UI',
		'Source Han Sans CN', 'Source Han Serif CN', 'SimSun', 'SimHei', 'Arial';
}

::-moz-placeholder {
	font-family: 'Arial', 'PingFang SC', 'Microsoft YaHei', 'Microsoft YaHei UI',
		'Source Han Sans CN', 'Source Han Serif CN', 'SimSun', 'SimHei', 'Arial';
}

::-ms-input-placeholder {
	font-family: 'Arial', 'PingFang SC', 'Microsoft YaHei', 'Microsoft YaHei UI',
		'Source Han Sans CN', 'Source Han Serif CN', 'SimSun', 'SimHei', 'Arial';
}
select::-ms-expand {
	display: none;
}

input[type='submit'] {
	border: none;
	-webkit-border-radius: 0;
	border-radius: 0;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	font-family: 'Arial', 'PingFang SC', 'Microsoft YaHei', 'Microsoft YaHei UI',
		'Source Han Sans CN', 'Source Han Serif CN', 'SimSun', 'SimHei', 'Arial';
}

body {
	font-family: 'Arial', 'PingFang SC', 'Microsoft YaHei', 'Microsoft YaHei UI',
		'Source Han Sans CN', 'Source Han Serif CN', 'SimSun', 'SimHei', 'Arial';
}
textarea,
select,
input {
	font-family: 'Arial', 'PingFang SC', 'Microsoft YaHei', 'Microsoft YaHei UI',
		'Source Han Sans CN', 'Source Han Serif CN', 'SimSun', 'SimHei', 'Arial';
}
.slide1 {
	opacity: 0;
	visibility: hidden;
	transition: opacity 1s ease;
	-webkit-transition: opacity 1s ease;
}

.slide1.slick-initialized {
	visibility: visible;
	opacity: 1;
}
*:after,
*:before,
* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

/*.page-enter-active,
.page-leave-active {
	transition: opacity 0.6s, transform 0.6s;
}
.page-enter,
.page-leave-to {
	opacity: 0;
	transform: translateY(-6%);
}*/
.nested-enter-active,
.nested-leave-active {
	transition: all 0.3s ease-in-out;
}
.nested-enter,
.nested-leave-to {
	opacity: 0;
}
