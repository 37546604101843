.list-pro
	margin 0 -10px
	display flex
	flex-wrap wrap
	margin-top 40px
	li
		padding 10px
		min-width 0
		flex 0 1 33.3333%
	.img
		height 280px
		line-height 280px
		margin-bottom 30px
		text-align center
		&:hover
			img
				transform translateZ(0) translateY(-50%) translateX(-50%) scale(1.04, 1.04)
		img
			vertical-align middle
			transform: translateZ(0) scale(1.0, 1.0);
			transition .4s
			position absolute
			top 50%
			left 50%
			transform translateZ(0) translateY(-50%) translateX(-50%) scale(1.0, 1.0)
			text-align center
			max-height 100%
	.txt
		display flex
		justify-content space-between
		align-items center
		h3
			transition .3s
			font-size var(--fz20)
			padding-right 20px
			ell()
		i
			transition .3s
			transform: translateX(-20px)
			opacity 0
	a
		background-color #F4F6F8
		display block
		padding 30px
		transition background-color .3s
		&:hover
			background-color #E6E9F0
			h3
				color m
			i
				transform: translateX(0)
				opacity 1
				
.pro-detail
	display flex
	.sticky-wrapper
		width 835px
	.img
		height 835px
		width 835px
		line-height 835px
		background-color #F4F6F8
		text-align center
		transition none
		img
			vertical-align middle
			max-width 90%
			max-height 90%
	.txt
		padding-top 80px
		padding-left 80px
		flex 1
		img
			margin-top 70px
	h1
		font-size var(--fz36)
		margin-bottom 16px
	h4
		font-size var(--fz24)
		margin-bottom 24px
		margin-top 54px
	h3
		font-size var(--fz24)
		color m
		margin-bottom 28px
		margin-top -10px
	.text
		margin-bottom 1em
.table-param
	table
		width 100%
		color #666666
		text-align center
		th
			font-size var(--fz18)
			padding 18px 20px
			text-align center
			color #333
		td
			padding 18px 20px
		tr:nth-child(2n)
			background-color #F5F7F9
.list-recommend
	margin 0 -10px
	margin-top 30px
	.item
		padding 10px
		min-width 0
	.img
		@extend .list-pro .img
		background-color #F4F6F8
		padding-top 100%
		transition background-color .3s
		margin-bottom 20px
	.txt
		@extend .list-pro .txt
		justify-content flex-start
		i
			margin-left 10px
	a
		display block
		&:hover
			.img
				background-color #E6E9F0
			h3
				color m
			i
				transform: translateX(0)
				opacity 1
	.swiper-circle-arrow
		margin-top -32px
+padH()
	.pro-detail
		display block
		padding-right 0 !important
		.img
			height auto
			width 100%
			line-height inherit
			padding 280px 0
			img
				max-width 70%
		.txt
			padding-left 3%
			padding-right 3%
+mb()
	.pro-detail
		.img
			padding 250px 0
		.txt
			padding-top 50px
		h4
			margin-top 90px
	.swiper-recommend
		.swiper-wrapper
			display flex
			flex-wrap wrap
		.swiper-slide
			margin-bottom 20px
			flex 0 1 50%
			min-width 0
		.swiper-circle-arrow
			display none
		.img
			margin-bottom 24px
		.txt
			i
				opacity 1
				transform translateX(0)
				font-size 12px
