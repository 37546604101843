.m_header
    display none
    position fixed
    width 100%
    z-index 100
    height 120px
    background-color #fff
    transition .3s
    padding 0 30px
    align-items center
    border-bottom 1px solid #eee
.m_logo
	img
		height 30px
.m_nav_btn
	margin-left auto
	.icon-search
		color #333
		padding-right 15px
		margin-right 15px
		margin-left 0
		font-size 20px
.nav_toggle
	.icon-x
		display none
	.iconfont
		font-size 20px
		color #333
	&.active
		.icon-x
			display inline-block
		.icon-menu
			display none
#m-nav
    position fixed
    top 0
    right 0
    bottom 0
    z-index 0
    background-color #fff
    transition .3s
    width 100%
    padding-top 120px
    opacity 0
    visibility hidden
    z-index -10
    .icon-close
        font-size 30px
#m-nav.pm_open
	visibility visible
	opacity 1
	z-index 90
.close_m_nav
    text-align right
.pm_overlay
    width 100%
    height 100%
    padding 0
    margin 0
    position fixed
    z-index 80
    left 0
    top 0
    animation fadeOut .5s both ease
    transition .3s
    background-color rgba(0,0,0,.4)
    display none !important
    &.pm_show
        display block
        animation fadeIn .3s both ease
    &.pm_hide
        display none
.m-nav-list
    width 100%
   	border none
    li > a
    	display block
    	color #333
    	line-height 90px
    	font-size var(--fz28-m)
    	position relative
    .iconfont
    	font-size var(--fz28-m)
    	position absolute
    	top 0
    	bottom 0
    	right 20px
    	transition .3s
    > li > a
    	border-top 1px solid rgba(255,255,255,.2)
    	border none
    .on
    	.iconfont
	    	transform rotate(90deg);
	    	color m
.m_nav
	.sub-menu
		display none
		overflow hidden
		padding-bottom 20px
		a
		    font-size 14px
		    color #333
		    text-indent 1em
		    display block
		    line-height 2.8
		    padding-left 0
		    padding-right 0
.m_nav .cell
	padding-left 50px
	padding-right 50px
	padding-top 36px

/*******************************
	-------[=透明头部]-------
********************************/
.transparent-hd
	.m_header
		background none
		border-bottom none
		transition .3s
	.m_logo
		img
			filter brightness(0) invert(1)
			transition .3s
	.m_nav_btn
		i
			color #fff
// 白色头部 弹出菜单
.body-show-m-menu
	.m_header
		background-color #fff
	.m_logo
		img
			filter none
	.m_nav_btn
		i
			color #333
.m_header.white-hd
	background-color #fff
	border-bottom 1px solid #eee
	.m_logo
		img
			filter none
	.m_nav_btn
		i
			color #333
+padW()
	#hd
		display none
	.m_header
		display flex
