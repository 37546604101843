@font-face {
    font-family: 'SF';
    src: url('../fonts/SFUIDisplay-Thin.woff2') format('woff2'),
        url('../fonts/SFUIDisplay-Thin.woff') format('woff'),
        url('../fonts/SFUIDisplay-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}


.list-culture h6
.ban-con p
	font-family 'SF'