.toast
	position fixed
	top 50%
	left 50%
	transform translateY(-50%) translateX(-50%)
	text-align center
	background m
	color #fff
	padding 10px 40px
	border-radius 6px
	min-width 120px
	z-index 500
	font-size 16px