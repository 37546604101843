.t1
	margin-bottom 26px
	margin-bottom 30px
	h3
		font-size var(--fz42)
		line-height 1
		color #000
.t2
	font-size var(--fz48)
	margin-bottom 20px
.t3
	font-size var(--fz36)
	line-height 1.3
.t4
	font-size var(--fz24)
	margin-bottom 18px
.t3
	margin-bottom 10px
	h3
		line-height 1.4
		font-size var(--fz36)
		font-weight bold
	.text
		margin-top 6px
		margin-bottom 30px
		p
			white-space pre-wrap
.t3-white
	h3
		color #fff
