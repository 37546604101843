.list-duty
	display flex
	flex-wrap wrap
	margin 0 -10px
	li
		flex 0 1 50%
		height 350px
		overflow hidden
		position relative
		padding 0 10px
		margin-bottom 20px
		&:hover
			.bg
				transform: scale(1.1);
			.con
				&:after
					opacity 1
	.pad
		overflow hidden
		height 100%
		display block
		position relative
	.bg
		height 100%
		transition 1s
	.con
		position absolute
		bottom 0
		left 0
		right 0
		padding 30px 30px 30px 30px
		z-index 10
		&:after
			content ''
			position absolute
			left 0
			right 0
			top 0
			bottom 0
			background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,88,185,0.65) 100%);
			opacity 0
			transition .3s
			cover()
	h3
	.text
		color #fff
		position relative
		z-index 10
	h3
		font-size var(--fz24)
		margin-bottom 16px
	.text
		height 0
		transition .4s
		overflow hidden
.row-duty2
	.t4
		margin-bottom 6px
	.hd
		margin-bottom 40px
.swiper-public
	.item
		display flex
		background-color #fff
		height 440px
	h4
		font-size var(--fz20)
		hidden(2)
		margin-bottom 32px
	.img-hover
		flex 0 1 50%
		.img
			height 100%
	.txt
		flex 0 1 50%
		padding 60px 50px
	.text
		hidden(8, 1.75)
	em
		color #333
		font-size var(--fz20)
.swiper-public
	.swiper-pagination-circle
		margin-top 40px
		text-align center
+mb()
	.list-duty
		display block
		.bg
			height 310px
			position relative
			&:after
				content ''
				position absolute
				left 0
				right 0
				bottom 0
				background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,88,185,0.65) 100%);
				transition .3s
				height 50%
				cover()
			h3
				position absolute
				left 0
				right 0
				bottom 0
				color #fff
				padding 20px 30px
				margin-bottom 0
		li
			height auto
			margin-bottom 30px
		.con
			position static
			background-color #F5F7F9
			h3
				display none
			&:after
				display none
		.text
			height auto !important
			color #333
		h3
			color #333
	.list-duty li:hover .bg
		transform none
	.row-duty2 
		padding-top 80px
		padding-bottom 80px
		.hd
			margin-bottom 60px
	.swiper-public
		.item
			display block
			height auto
		.img-hover
			height 388px
		.text
			-webkit-line-clamp 4
			height 7em
		.txt
			padding-left 30px
			padding-right 30px
			padding-top 50px
			padding-bottom 50px