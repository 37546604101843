.row-contact1
	display flex
	height 510px
	.img
		flex 0 1 50%
	.map
		flex 0 1 50%
	#map
		height 100%
.row-contact2
	.t1
		margin-bottom 50px
.row-contact3
	padding 80px 0
	background-image url("../images/bg-contact.jpg")
	cover()
	.wp
		display flex
		justify-content space-between
	h3
		color #fff
	.t1
		flex 0 1 20%
	.item
		color #fff
		font-size var(--fz20)
		line-height 1.7
	.line
		border-left 1px solid #fff
.BMapLabel
	display none !important
+mb()
	.row-contact1
		padding-top 100px
		height auto
		display block
		padding-left 40px
		padding-right 40px
		.img
			height 350px
	.map
		height 350px
	.row-code
		.btn-full
			width 100%
	.inp-code
		margin-bottom 50px
	.row-contact3
		padding-top 100px
		padding-bottom 110px
		.wp
			display block
		.line
			border none
			border-top 1px solid rgba(255,255,255,.5)
			margin 50px 0
		.t1
			margin-bottom 50px
