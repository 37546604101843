.intro-row1
	padding-bottom 0
	.wp
		display flex
	.t1
		flex 0 1 48%
	.bd
		flex 1
.i-row2-intro
	padding-top 100px
.intro-gallery
	display flex
	.left
		flex 0 1 50%
	.right
		flex 0 1 50%
	.cell
		height 316px
		display flex
		flex-direction column
		justify-content center
		align-items center
		background-size cover
		background-position 50%
		background-repeat no-repeat
		p
			color m
	.gallery-row
		display flex
		.cell
			flex  0 1 50%
	.left
		.gallery-row
			.cell
				background-color #F5F7F9
	.right
		.cell-width
			background-image url("../images/intro3.png")
			background-color #116BB1
			h3
			p
				color #fff
		.gallery-row
			.cell:nth-child(1)
				background-image url("../images/intro4.png")
			.cell:nth-child(2)
				background-color #083C5C
				h3
				p
					color #fff
.intro-history
	.t1
		margin-bottom 90px
.swiper-history-bd
	background url("../images/logo-history.png") no-repeat 50% 0%
	background-size 912px
	margin-bottom 130px
	min-height 240px
	h3
		font-size var(--fz42)
		color #999
		transition .3s
		margin-bottom 4px
	.txt
		padding-right 130px
		color #999
		transition .3s
		padding-left 2px
	p
		line-height 1.9
		padding-left .8em
		position relative
		&:before
			left 0
			content ''
			width 5PX
			height 5PX
			border-radius 50%
			background-color m
			position absolute
			top .8em
	.swiper-slide-active
		h3
			color m	
		.txt
			color #333
.year-box
	position relative
	padding-left 60px
	padding-right 60px
.swiper-history-hd
	.swiper-slide
		width 14.2857%
		text-align center
		padding-top 26px
		position relative
		cursor pointer
		&:hover
			h4
				color #383838
		&:before
			content ''
			position absolute
			width 9PX
			height 9PX
			border-radius 50%
			background-color m
			top 20px
			left 50%
			transform translateX(-50%) translateY(-50%)
			transition .3s
		&:after
			content ''
			position absolute
			left 0
			right 0
			top 20px
			border-top 1px solid m
	.swiper-slide:first-child
		&:after
			left -200px
	.swiper-slide:last-child
		&:after
			right -200px
	h4
		font-size var(--fz20)
		color #999999
		transition .3s
		margin-top 10px
	.on
		h4
			color m
	.swiper-slide.on:before
		transform: scale(1.2) translateX(-50%) translateY(-50%)
		box-shadow: 0 0 0 4px #CFDFEB
		transform-origin 50% 0
.list-culture
	display flex
	li
		flex 0 1 33.333%
		// height 640px
		overflow hidden
		position relative
		&:hover
			.bg
				transform: scale(1.1);
	.bg
		padding-top 100%
		// height 100%
		transition 1s
		transform: scale(1.01);
	.con
		position absolute
		bottom 0
		left 0
		right 0
		padding 60px 70px
		z-index 10
	h6
	h3
	.text
		color #fff
	h6
		font-size var(--fz42)
		line-height 1.5
	h3
		font-size var(--fz24)
		margin-bottom 28px
	.text
		transition .6s
		height 0
		overflow hidden
.intro-culture
	padding-bottom 0
	.t1
		margin-bottom 52px
.intro-honor
	.t1
		margin-bottom 50px
.swiper-honor
	.item
		background-color #F5F7F9
		padding-top 100%
		position relative
		.pic
			position absolute
			left 0
			right 0
			top 0
			bottom 0
			display flex
			flex-direction column
			justify-content center
			align-items center
		img
			max-width 70%
			max-height 70%
.anchor
	position: relative;
	height 0
	top: -70px;
	top 0
	visibility: hidden;
+padW()
	.anchor
		top -120px
		top 0
+mb()
	.intro-row1
		.wp
			display block
		.t1
			margin-bottom 40px
	.intro-gallery 
		display block
		.cell
			height 250px
	.swiper-history-arrow
		top 4px
	.year-box
		padding-left 50px
		padding-right 50px
	.swiper-history-bd
		background-size 100%
		background-position 50%
		margin-bottom 100px
		.txt
			padding-right 0
		h3
			margin-bottom 24px
		p
			margin 2px 0
	.intro-history
		.t1
			margin-bottom 80px
	.list-culture
		display block
		.text
			height auto !important
		.con
			padding 40px
	.swiper-honor
		margin-right -40px
		margin-top 80px
		.item
			height 560px
			width 100%
		img
			max-height 80%
