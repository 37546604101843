.row-project-quality
	padding-top 100px
	padding-bottom 110px
.row-project-quality
	.img
		order 1
	.img
		flex 0 1 52%
		text-align right
	.txt
		flex 0 1 48%
		padding-right 100px
	.wp
		display flex
.row-process-quality
	padding-top 100px
	padding-bottom 100px
.row-improve
	padding-top 100px
	padding-bottom 100px
.row-project-quality .t3
	margin-bottom 30px
.row-process-quality .t3
	margin-bottom 38px
.row-improve
	.t3
		margin-bottom 28px
	.t4
		margin-bottom 21px
.list-process
	display flex
	margin 0 -2px
	.item
		flex 0 1 25%
		padding 0 2px
		p
			margin-bottom 10px
		&:nth-child(1)
			.pad
				background-color #021D3A
		&:nth-child(2)
			.pad
				background-color #023A55
		&:nth-child(3)
			.pad
				background-color #045BA8
		&:nth-child(4)
			.pad
				background-color #10ABE8
		&:nth-child(1)
			.con
				width 170px
				display flex
				flex-wrap wrap
			p
				flex 0 1 50%
		&:nth-child(2)
			.con
				display flex
				flex-wrap wrap
			p
				flex 0 1 50%
	.pad
		padding 46px 30px
		height 100%
		position relative
	h4
		font-size var(--fz20)
		color #fff
		margin-bottom 26px
		// padding-right 80px
		max-width 11em
		line-height 1.4
	.con
		color #fff
	i
		position absolute
		right 30px
		top 30px
		line-height 1
		font-size var(--ic48)
		color rgba(255,255,255,.5)
.list-improve
	background url("../images/bg-improve.jpg") no-repeat left top
	height 480px
	cover()
	display flex
	margin-bottom 66px
	li
		flex 0 1 20%
		border-right 1px solid rgba(255,255,255,.6)
		text-align center
		transition .3s
		&:hover
			background-color rgba(0,0,0,.3)
			em
				background-color #10ABE8
				border-color #10ABE8
	em
		border-radius 50%
		border 1px solid #fff
		width 42px
		transition .3s
		height 42px
		line-height 40px
		text-align center
		display inline-block
		font-size var(--fz18)
		color #fff
	.num
		margin-bottom 20px
	.text
		color #fff
	.pad
		padding-top 50px
		padding-left 20px
		padding-right 20px
.list-application
	display flex
	margin 0 -12px
	li
		padding 0 12px
		flex 0 1 33.333%
	.img
		height 278px
	.pad
		background-color #fff
		height 100%
		display flex
		flex-direction column
	.txt
		padding 26px 30px
		flex 1
		display flex
		flex-direction column
		justify-content center
	h4
		font-size var(--fz20)
	p
		color #666
+padW()
	.list-process
		h4
			max-width 8em
+padH()
	.list-process
		display grid
		grid-template-columns repeat(2, 1fr) 
		grid-auto-rows 1fr
		.item
			flex 0 1 50%
			padding-bottom 4px
		h4
			max-width 17em
+mb()
	.row-project-quality
		.wp
			flex-direction column
		.img
			margin-top 50px
	.list-process
		grid-template-columns repeat(1, 1fr) 
		.item
			padding-bottom 10px
			p
				margin-bottom 20px
		.pad
			padding-left 50px
			padding-right 50px
			padding-top 80px
			padding-bottom 80px
		h4
			margin-bottom 40px
			line-height 1.5
			max-width 12em
		i
			top 60px
			right 50px
		.item:nth-child(1) .con
			width 300px
	.row-improve .t4
		margin-bottom 40px
	.list-improve
		white-space nowrap
		overflow-x auto
		height 910px
		li
			flex 0 0 580px
		.pad
			padding-top 110px
		em
			width 77px
			height 77px
			line-height 75px
		.num
			margin-bottom 30px
	.list-application
		display grid
		grid-template-columns repeat(1, 1fr) 
		grid-auto-rows 1fr
		.img
			height 376px
		li
			margin-bottom 30px
		.txt
			padding-top 36px
			padding-bottom 36px