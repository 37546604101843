.breadcrumbs-bar
	border-bottom 1px solid #eee
	background-color #fff
	width 100%
.breadcrumbs
	padding 16px 0
	a
		vertical-align middle
		opacity .5
		transition .3s
		&:hover
			opacity 1
			color #333
		&:after
			content '/'
			display inline-block
			vertical-align middle
			margin 0 8px
			font-size var(--fz20)
			color #333
			vertical-align middle
			line-height 1
		&:last-child:after
			display none
	.on
		opacity 1