.btn
	border none
	background none
	text-align center
	display inline-block
	transition background-color .3s, color .3s
	cursor pointer
	-webkit-appearance none
	-webkit-border-radius none
	border-radius 0
	text-indent 0
	*
		vertical-align middle
.btn-big
	width 197px
	height 54px
	line-height 54px
	font-size var(--fz18)
	padding 0 10px
	border none
.btn-sub
	@extend .btn-big
	background-color #03579F
	color #fff
	transition .3s
	&:hover
		background-color orange
.btn-yellow
	@extend .btn-big
	background-color #E95506
	color #fff
	transition .3s
	&:hover
		background-color m
		color #fff
.btn-round
	width 100px
	height: 36px;
	line-height: 36px;
	border-radius 50px
	background-color m
	transition .3s
	color #fff
	&:hover
		color #fff
		opacity .8
.btn-medium
	width 160px
	height: 42px;
	line-height: 42px;
	border-radius 4px
+mb()
	.btn-big
		width 100%
		height 80px
		line-height 80px
