.application-index
	padding-top 110px
	padding-bottom 100px
	.hd
		text-align center
	.t1
		margin-bottom 20px
.list-industry
	display flex
	flex-wrap wrap
	margin 0 -10px
	margin-top 60px
	li
		flex 0 1 50%
		// height 590px
		overflow hidden
		position relative
		padding 0 10px
		margin-bottom 20px
		&:hover
			.bg
				transform: scale(1.1);
	.pad
		overflow hidden
		position relative
		display block
		&:after
			content ''
			position absolute
			left 0
			right 0
			bottom 0
			height 240px
			background url("../images/mask-app.png") no-repeat left top
			cover()
	.bg
		padding-top 100%
		transition 1s
	.con
		position absolute
		bottom 0
		left 0
		right 0
		padding 30px 30px 50px 30px
		z-index 10
	h3
	.text
		color #fff
	h3
		font-size var(--fz28)
		margin-bottom 20px
	.text
		margin-bottom 34px
		hidden(2,1.75)
+mb()
	.list-industry
		display block
		li
			margin-bottom 30px