
/*******************************
	-------[=底部]-------
********************************/
#fd
	position relative
	background #232323 url("../images/fd-bg.jpg") no-repeat 50% bottom
	z-index 60
	clear both
	padding-top 110px
	color rgba(255,255,255,.6)
	.wp
		display flex
		justify-content space-between
	.fd-l
		flex 0 1 40%
		color rgba(255,255,255,.6)
		font-size var(--fz14)
	.fd-r
		flex 0 1 52%
		display flex
		justify-content space-between
.fd-r
	display flex
.fd-link
	line-height 2.4
	a
		color #fff
		display block
	dt
		margin-bottom 8px
	dd
		a
			color rgba(255,255,255,.6)
			font-size var(--fz14)
			transition .3s
		a:hover
			color #fff
.fd-copy
	color #fff
	font-size var(--fz14)
	padding 30px 0
	text-align center
	color rgba(255,255,255,.6)
	margin-top 70px
	p
		display inline-block
	a
		color rgba(255,255,255,.6)
		margin-left 10px
		&:hover
			color #fff
		span
		a
			margin-right 20px
.fd-logo
	img
		width 155px
.fd-subscription
	margin-top 60px
	margin-bottom 30px
	p
		margin-bottom 10px
	form
		position relative
	button
		border none
		background none
		position absolute
		right 0
		position absolute
		top 50%
		transform translateY(-50%)
		right 0
		padding-right 15px
		cursor pointer
		i
			font-size var(--ic12)
			color rgba(255,255,255,.6)
			transition .3s
		&:hover
			i
				color #fff
.fd-share
	display inline-block
	margin-left 16px
	a
		vertical-align middle
		width 30px
		display inline-block
		position relative
		margin-right 1px
		&:hover
			i
				color m
			img
				opacity 1
				visibility visible
				margin-top -6px
				z-index 1
		img
			width 80px
			height 80px
			max-width 80px
			position absolute
			left 50%
			top 0
			transform translateX(-50%) translateY(-100%);
			transition .3s
			margin-top 0
			opacity 0
			visibility hidden
			z-index -1
	i
		color #fff
		transition .3s
		font-size var(--fz20)
+padW()
	#fd .fd-l
		flex 0 1 34%
	#fd .fd-r
		flex 1
+padH()
	#fd
		.fd-r
			display none
		.fd-l
			flex 1
			display flex
			justify-content space-between
	.fd-subscription
		margin-top 0
+mb()
	.fd-subscription
		width auto
		margin-bottom 55px
		button
			padding-right 25px
		.inp
			height 80px
			padding-left 25px
			padding-right 25px
		p
			margin-bottom 24px
	#fd
		.fd-l
			display block
			border-bottom 1px solid rgba(255,255,255,.2)
			padding-bottom 80px
	.fd-logo
		margin-bottom 70px
		img
			width 230px
	.fd-copy
		margin-top 0
		padding 56px 40px
		text-align left
	.fd-share a
		width auto
		margin-right 14px