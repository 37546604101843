@font-face {
  font-family: "iconfont";
  /* Project id 3843186 */
  src: url("../fonts/iconfont.woff2?t=1677461319333") format('woff2'),
       url("../fonts/iconfont.woff?t=1677461319333") format('woff'),
       url("../fonts/iconfont.ttf?t=1677461319333") format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-muted:before {
  content: "\e8b8";
}

.icon-play:before {
  content: "\e8b9";
}

.icon-menu:before {
  content: "\e790";
}

.icon-youtube:before {
  content: "\f274";
}

.icon-facebook:before {
  content: "\e8e7";
}

.icon-linkin:before {
  content: "\e6001";
}

.icon-x:before {
  content: "\eb6a";
}

.icon-plus:before {
  content: "\e727";
}

.icon-minus:before {
  content: "\e615";
}

.icon-contact:before {
  content: "\e6a9";
}

.icon-tel:before {
  content: "\e6aa";
}

.icon-address:before {
  content: "\e6ab";
}

.icon-gear:before {
  content: "\e6ac";
}

.icon-prev:before {
  content: "\e629";
}

.icon-next:before {
  content: "\e62b";
}

.icon-prev-page:before {
  content: "\e6002";
}

.icon-next-page:before {
  content: "\e6003";
}

.icon-down:before {
  content: "\e728";
}

.icon-left-bold:before {
  content: "\e668";
}

.icon-right-bold:before {
  content: "\e669";
}

.icon-more-arrow:before {
  content: "\e66a";
}

.icon-search:before {
  content: "\e66b";
}

.icon-weibiaoti-2-04:before {
  content: "\e66c";
}

.icon-arrow-right-long:before {
  content: "\e66d";
}

.icon-more:before {
  content: "\e66e";
}

.icon-more-arrow-circle:before {
  content: "\e66f";
}

.icon-oil:before {
  content: "\e670";
}

.icon-weibiaoti-2-16:before {
  content: "\e671";
}

.icon-lab:before {
  content: "\e672";
}

.icon-leaf:before {
  content: "\e673";
}

.icon-right-circle:before {
  content: "\e674";
}

.icon-wind:before {
  content: "\e675";
}

.icon-setting:before {
  content: "\e676";
}

.icon-left-circle:before {
  content: "\e677";
}

.icon-weibo:before {
  content: "\e678";
}

.icon-ship:before {
  content: "\e679";
}

.icon-up:before {
  content: "\e67b";
}

.icon-team:before {
  content: "\e67c";
}

.icon-machine:before {
  content: "\e67d";
}

.icon-douyin:before {
  content: "\e67e";
}

.icon-weixin:before {
  content: "\e67f";
}

.icon-various:before {
  content: "\e680";
}

.icon-book:before {
  content: "\e681";
}

.icon-varied:before {
  content: "\e682";
}

.icon-hand:before {
  content: "\e683";
}

.icon-brain:before {
  content: "\e684";
}

.icon-discuss:before {
  content: "\e685";
}

.icon-board:before {
  content: "\e686";
}

.icon-layer:before {
  content: "\e687";
}

.icon-loc:before {
  content: "\e688";
}