.tec-row
	margin-bottom 80px
	display flex
	.img
	.txt
		flex 0 1 50%
	.bg
		height 100%
		min-height 410px
	.txt
		padding 55px 60px
		background-color #F4F6F8
	.t3
		margin-bottom 15px
.tec-ico-list
	display flex
	margin-top 52px
	margin-bottom 30px
	li
		margin-right 9px
		flex 0 1 90px
		height 90px
		border-radius 8px
		overflow hidden
		position relative
		transform perspective(1000px)
		transform-style preserve-3d
	.pad
		transform perspective(1000px)
		transition 0.6s
		transform-style preserve-3d
		height 100%
	.ico
	.con
		display flex
		flex-direction column
		justify-content center
		align-items center
		backface-visibility hidden
		transition 0.6s
		transform rotateY(0deg)
		transform-style preserve-3d
		position absolute
		top 0
		left 0
		width 100%
		height 100%
	.ico
		background-color #D9E7F1
		height 90px
		z-index 2
		transform: rotateY(0)
	i
		font-size var(--fz36)
		color m
	.con
		padding-left 12px
		padding-right 12px
		background-color m
		color #fff
		text-align center
		z-index 1
		transform: rotateY(-180deg)
	li:hover
		.con
			transform: rotateY(0)
		.ico
			transform: rotateY(180deg)
.tec-row:nth-child(2)
	.img
		order 1
.row-development
	padding-top 90px
.tec-power
	margin-bottom 100px
	margin-top 110px
	.t4
		margin-bottom 0
	.hd
		display flex
		margin-bottom 40px
	.t3
		flex 0 1 40%
	.sub-hd
		flex 1
.list-power
	display flex
	margin 0 -12px
	.pad
		padding 0 12px
	.img
		padding-top 76%
	li
		flex 0 1 33.3333%
.tec-digital
	background url("../images/bg-dig.jpg") no-repeat left top
	padding-top 60px
	padding-bottom 70px
	cover()
	.wp
		display flex
		align-items center
	.txt
		flex 0 1 42%
	.img
		flex 0 1 58%
		text-align right
.row-innovation
	margin 100px 0
.tec-innovation
	display flex
	align-items center
	.img
		flex 0 1 48%
		padding 33px
		padding-right 0
	.txt
		flex 1
		padding-left 86px
		padding-right 86px
.row-patent
	margin 100px 0
.swiper-patent
	margin-top 40px
	.img
		display block
+mb()
	.tec-power .t3
	.tec-digital .t3
		margin-bottom 30px
	.tec-row
		display block
		margin-bottom 60px
		.bg
			min-height 1px
			height 376px
		.txt
			padding 50px 30px
	.tec-ico-list li
		flex 1
		height 113px
		.ico
			height 113px
		.con
			font-size 12px
	.tec-power
		.hd
			display block
			margin-bottom 50px
	.list-power
		display block
		li
			margin-bottom 30px
	.tec-digital
		background-image url("../images/m-bg-dig.jpg")
		background-position 0 bottom
		background-size 100% auto
		padding-top 100px
		.wp
			display block
		.txt
			margin-bottom 40px
	.tec-innovation
		display block
		.img
			padding-top 60px
			padding-left 25px
			padding-right 25px
		.txt
			padding-left 25px
			padding-right 25px
			padding-top 18px
			padding-bottom 70px
		.t3
			margin-bottom 18px
	.swiper-patent 
		margin-right -40px
		.swiper-circle-arrow
			display none