.product-row
	margin 80px 0
	h3
		font-size var(--fz28)
		margin-bottom 20px
.product-row1
	margin-top -70px
	position relative
	z-index 10
	.wp
		display flex
.pro-txt
	flex 0 1 500px
	height 630px
	background #023A5B url("../images/bg-app.png") no-repeat left top
	padding 75px 40px
	display flex
	flex-direction column
	justify-content space-between
	h4
		font-size var(--fz28)
		color #fff
		margin-bottom 16px
	.text
		color #fff
		hiddenFix(10,1.75)
.pro-img
	flex 1
	text-align center
	min-width 0
	display flex
	flex-direction column
	justify-content center
	.swiper-pro
		width 100%
	h4
		font-size var(--fz20)
	.img-contain
		height 400px
		margin 0 auto
		align-items center
		margin-bottom 20px
		display flex
		flex-direction column
		justify-content center
		img
			max-width 60%
			max-height 90%
.swiper-pagination-pro
	margin-top 60px
.list-advantage
	margin 0 -10px
	display grid
	grid-template-columns repeat(4, 1fr) 
	grid-auto-rows 1fr
	li
		// flex 0 1 25%
		padding 0 10px
		margin-bottom 20px
		&:hover
			.pad
				background-color m
				p
					color #fff
				&:after
					filter: brightness(1000%);
	p
		font-size var(--fz20)
		color #000
		transition .3s
	.pad
		padding 30px
		background #F4F6F8
		height 100%
		position relative
		transition .3s
		&:after
			content ''
			position absolute
			right 0
			width 187px
			height 134px
			background url("../images/logo-advantage.png") no-repeat left top
			background-size 100%
			bottom 0
			transition .3s
.swiper-case
	.img
		height 250px
	h4
		font-size var(--fz20)
		margin-top 16px
		ell()
+padW()
	.pro-txt .text
		hiddenFix(5,1.75)
+padH()
	.list-advantage
		grid-template-columns repeat(3, 1fr) 
+mb()
	.product-row1
		margin-top 0
		padding-top 100px
		.wp
			display block
	.swiper-pagination-pro
		margin-bottom 60px
		margin-top 50px
	
	.list-advantage
		grid-template-columns repeat(1, 1fr) 
		li
			margin-bottom 30px
		.pad
			padding-top 50px
			padding-bottom 50px
		.pad:after
			width 316px
			height 225px
	.swiper-case
		.swiper-wrapper
			display block
		.img
			height 340px
		h4
			padding-bottom 60px
			margin-top 30px
		.swiper-pagination-line
			display none