lg()
	@media screen and (max-width: 1700px)
		{block}
padW()
	@media screen and (max-width: 1100px)
		{block}
padH()
	@media screen and (max-width: 1000px)
		{block}
mb()
	@media screen and (max-width: 760px)
		{block}
hoverPc()
	@media not all and (pointer: coarse)
		&:hover
			{block}
line()
	&:after
		content ''
		position absolute
		left 0
		background-color m
		height 1px
		transition .3s
		width 0
		bottom -4px
	&:hover:after
	&.on:after
		width 100%
	&.current:after
		width 100%
hidden(n,lh = 1.6)
	overflow hidden
	text-overflow ellipsis
	display -webkit-box
	-webkit-box-orient vertical
	-webkit-line-clamp n
	max-height (n*lh)em
hiddenFix(n,lh = 1.6)
	overflow hidden
	text-overflow ellipsis
	display -webkit-box
	-webkit-box-orient vertical
	-webkit-line-clamp n
	height (n*lh)em
.txt-line4
	-webkit-line-clamp 4
	height 5.4em
.txt-line3
	-webkit-line-clamp 3
.txt-line2
	-webkit-line-clamp 2
	height 3.8em