.accordion
	display flex
	li
		flex 1
		color #fff
		transition .5s
		position relative
		line-height 1.5
		&:after
			content ''
			position absolute
			width 100%
			background-color #fff
			height 40%
			bottom 0
			z-index 0
			transition .3s
			background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
			left 0
	.con
		position absolute
		bottom 0
		left 0
		right 0
		padding-bottom 70px
		transition .3s
		z-index 10
		> .iconfont
			font-size var(--fz60)
	.before
		text-align center
	.after
		opacity 0
		left 0
		right 0
		padding-left 10%
		padding-right 10%
	.bg
		height 700px
		position relative
	h3
		font-size var(--fz36)
		color #fff
		margin-bottom 17px
	.text
		color #fff
		width 560px
		hidden(4,1.75)
		margin-bottom 38px
	li
		&.show-after
			flex 0 1 50%
			.before
				opacity 0
			.after
				opacity 1
.accordion
	.more-arrow-link:hover
		color #fff
+mb()
	ul.accordion
		display none
.swiper-accordion
	color #fff
	.before
		display none
	.after
		opacity 1
		padding-left 40px
		padding-right 40px
		padding-bottom 260px
	.text
		max-width inherit
		margin-bottom 60px
	.bg
		height 900px
	h3
		margin-top 14px
	.swiper-slide
		position relative
		&:after
			content ''
			position absolute
			width 100%
			background-color #fff
			height 60%
			bottom 0
			z-index 0
			transition .3s
			background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
			left 0
	.swiper-pagination-solid
		margin-bottom 30px
+padH()
	.accordion .text
		width 500px
+mb()
	.accordion .text
		width auto