.swiper-pagination-circle
	.swiper-pagination-bullet
		width 13px
		height 13px
		border 1px solid m
		opacity 1
		margin 0 7px
		background none
	.swiper-pagination-bullet-active
		background-color m
.swiper-history-arrow
	position absolute
	top 10px
	text-align center
	z-index 60
	transition .3s
	line-height 1
	color m
	&:hover
		color m
	i
		font-size var(--fz22)
.swiper-button-disabled.swiper-history-arrow
	color #A1A1A1 !important
.swiper-history-prev
	left 0
.swiper-history-next
	right 0
// banner箭头
.banner-arrow
	position absolute
	font-size 38px
	color #fff
	top 50%
	z-index 20
	transform translateY(-50%)
.banner-prev
	left 40px
.banner-next
	right 40px
/*******************************
	-------[=圆形]-------
********************************/
.swiper-circle-arrow
	position absolute
	top 50%
	transform translateY(-50%)
	width 50px
	height 50px
	line-height 50px
	text-align center
	background-color rgba(0,0,0,.5)
	z-index 60
	border-radius 50%
	transition .3s
	&:hover
		background-color m
	i
		color #fff
		font-size var(--fz22)
.swiper-circle-prev
	left 20px
.swiper-circle-next
	right 20px
.swiper-pagination-line 
	margin-top 58px
	font-size 0
	text-align center
	span
		width 32px
		border-radius 0
		height 0
		background none
		height 20px
		opacity 1
		transition .3s
		position relative
		&:after
			border-top 1px solid #D3D3D3
			content ''
			position absolute
			top 50%
			top 10px
			left 0
			right 0
	.swiper-pagination-bullet-active
		width 74px
		&:after
			border-color m
.banner-box
	position relative
	.swiper-pagination
		position absolute
		left auto
		right 0
		bottom 0
		transform translateY(50%) translateX(50%)
		width 550px
		height 550px
		border 1px solid rgba(222,222,222,.5)
		border-radius 50%
	span 
		display block
		position absolute
		top 50%
		left 50%
		width 14px
		height 14px
		margin -7px
		background-color #fff
		opacity 1
		border-radius 50%
		&:after
			content ''
			position absolute
			left -6px
			right -6px
			bottom -6px
			top -6px
			border .08rem solid #E85623
			border-radius 50%
			transform: scale(0);
			transition .3s
		&:before
			content ''
			position absolute
			left -13px
			right -13px
			bottom -13px
			top -13px
			border .08rem solid rgba(232,86,35,.4)
			border-radius 50%
			transform: scale(0);
			transition .3s .1s
	.swiper-pagination-bullet-active
		&:after
			transform: scale(1);
		&:before
			transform: scale(1);
+mb()
	.swiper-pagination-circle .swiper-pagination-bullet
		width 7PX !important
		height 7PX !important
		margin-left 9px !important
		margin-right 9px !important
	.swiper-pagination-solid
		.swiper-pagination-bullet
			border none !important
			background-color #fff !important
		.swiper-pagination-bullet-active
			background-color m !important
	.swiper-container-horizontal .swiper-scrollbar
		margin-top 80px
		position static
		width 300px
		margin-left auto
		margin-right auto
		height 2PX
	.swiper-container-horizontal .swiper-scrollbar-drag
		background-color m