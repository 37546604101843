#banner
	height 100vh
	.swiper-slide
		height 870px
		height 100%
		overflow hidden
		cover()
	.bg
		height 100%
		position absolute
		left 0
		top 0
		width 100%
		height 100%
		transition 10s
	.move-in
		.cell
			transform translateY(0)
			opacity 1
	.move-out
		.cell
			transform translateY(-100px)
			opacity 0
	.swiper-pagination
		bottom 60px
	.swiper-pagination-bullet 
		position relative
		width 34px
		height 34px
		border 1px solid rgba(255,255,255,.2)
		opacity 1
		margin-left 9px
		margin-right 9px
		background none
		&:after
			content ''
			position absolute
			top 50%
			left 50%
			transform translateY(-50%) translateX(-50%)
			text-align center
			width 6px
			height 6px
			border-radius 50%
			background-color #fff
		svg 
			// width 34px
			// height 34px
			position absolute
			z-index 10
			left 0
			right 0
			top 0
			bottom 0
			margin -1PX
			transform rotate(-90deg)
	.ring 
		z-index 1000
		fill transparent
		stroke #fff
		stroke-width 1
		stroke-dasharray 100
		stroke-dashoffset 0
		stroke-linecap round
		opacity 0
	.swiper-pagination-bullet-active .ring
		opacity 1
		animation show100 5s linear
.video-btn
	position absolute
	right 40px
	bottom 15%
	i
		color #fff
		font-size 30px
	.icon-muted
		display none
@keyframes show100 {
    from {
        stroke-dashoffset: 100;
    }
    to {
        stroke-dashoffset: 0;
    }
}
.ban-con
	height 100%
	position relative
	z-index 10
	.cell
		display table-cell
		vertical-align middle
		color #fff
		transform translateY(-100px)
		transition .8s
		opacity 0
	.wp
		display table
		height 100%
		vertical-align middle
	h3
		font-size var(--fz64)
		line-height 1.1
		text-transform uppercase
		font-weight bold
		margin-bottom 16px
		color #fff
		
	p
		font-size var(--fz21)
		width 100%
		max-width 600px
	.con
		font-size var(--fz21)
		display block
		white-space break-spaces
.i-row1
	position relative
	padding-right 360px
	cover()
	.wp-full
		padding 150px 0 130px 0
		background url("../images/bg1.jpg") no-repeat 50%
		cover()
	.t1
		margin-bottom -60px
		position relative
		z-index 10
.swiper-solution-outer
	.item
		display flex
	.intro
		// flex 0 1 34%
		padding-right 90px
		flex 1
		padding-top 30px
		padding-top 100px
	.text
		margin-bottom 60px
	&:after
		content ''
		position absolute
		right 110px
		top 0
		bottom 0
		background url("../images/bg-logo.png") no-repeat 50% 0
		background-size 100%
		width 640px
		
.swiper-solution-inner
	flex 0 1 640px
	margin-right 110px
	// flex 0 1 50%
	// width 50%
.swiper-solution-inner
	h4
		font-size var(--fz24)
		text-align center
		position absolute
		left 0
		bottom 70px
		width 100%
	.swiper-pagination
		bottom 0
		z-index 50
	.swiper-slide
		padding-top 0
		padding-bottom 130px
.solution-side
	width 360px
	background-color #fff
	top 0
	bottom 0
	position absolute
	right 0
	padding-bottom 130px
	padding-top 150px
.next-solution
	position absolute
	width 70px
	height 70px
	line-height 70px
	text-align center
	position absolute
	top 50%
	transform translateY(-50%) translateX(-50%)
	cursor pointer
	z-index 10
	box-shadow: 0 0 12px 5px rgba(0,0,0,.1);
	border-radius 50%
	transition .3s
	&:hover
		background-color m
		i
			color #fff
	i
		color m
		transition .3s
.swiper-solution-inner
	.img-contain
		height 450px
		line-height 450px
		text-align center
		img
			vertical-align middle
			max-height 100%
			max-width 60%
	.bg-logo
		background url("../images/bg-logo.png") no-repeat 50%
		background-size 100%
		background none
.swiper-product-thumb
	height 100%
	.img-contain
		width 60%
	h4
		font-size var(--fz24)
	.img-contain
		display inline-block
		background-size 60%
		margin-bottom 20px
	.item
		display flex
		height 100%
		justify-content center
		align-items center
		flex-direction column
	.cell
		text-align center
		width 100%
	.swiper-slide
		padding-bottom 130px
.i-row2
	padding-top 110px
	padding-bottom 100px
	position relative
	.wp
		display flex
	.img
		flex 0 1 46%
		.bg
			min-height 460px
			height 100%
	.txt
		flex 1
		padding-top 8px
		padding-left 90px
		display flex
		justify-content space-between
		flex-direction column
	.text
		margin-bottom 38px
.summary-number-index
	margin-top 30px
.summary-number
	h3
		line-height 1
		color m
		margin-bottom 14px
		span
			font-size var(--fz60)
			vertical-align top
		sup
			margin-left 4px
			font-size var(--fz32)
	p
		font-size var(--fz18)
.i-row2
	.summary-number
		display flex
		justify-content space-between
		li
			text-align center
	
.i-row3
	.hd
		margin-bottom 46px
	.t1
		display flex
		align-items center
		margin-bottom 20px
		.more
			margin-left auto
.intro-l
	width 44%
	.text
		margin-bottom 40px
.intro-v
	width 44%
	position absolute
	right 0
	padding-top inherit
	video
		width 100%

.news-box
	display flex
.news-l
	flex 0 1 46%
	min-width 0
	padding-left 1PX
	a
		display block
		position relative
		height 100%
		overflow hidden
		imgHover()
		&:after
			content ''
			position absolute
			width 100%
			bottom 0
			height 50%
			background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
			z-index 0
	.img
		position absolute
		left 0
		right 0
		top 0
		bottom 0
	.txt
		position absolute
		left 0
		right 0
		bottom 0
		z-index 10
		color #fff
		padding-right 30px
		padding-left 30px
		padding-bottom 30px
		h3
			color #fff
			font-size var(--fz24)
			margin-bottom 10px
			ell()
	.text
		hidden(2,1.75)
.news-r
	flex 0 1 54%
	padding-left 90px
	min-width 0
.list-news
	a
		position relative
		display flex
	.img
		flex 0 1 230px
		min-height 154px
		overflow hidden
		padding-left 1PX
		imgHover()
	.bg
		height 100%
	.txt
		padding-left 20px
		flex 1
		min-width 0
	li
		cf()
		border-top 1px solid g
		padding 40px 0
		&:first-child
			border none
			padding-top 0
		&:last-child
			padding-bottom 0
	h4
		color #C6C6C6
		font-size var(--fz16)
		line-height 2
	h3
		font-size var(--fz24)
		ell()
		overflow hidden
		line-height 1.3
		margin-bottom 20px
		transition .3s
	p
		color #737373
	a:hover
		h3
			color m
	.text
		hidden(2,1.75)
.i-row4
	padding-top 120px
	.t1
		margin-bottom 0
		.more
			position absolute
			top 50%
			transform translateY(-50%)
			right 0
	.hd
		display flex
		position relative
		align-items center
		margin-bottom 50px
	.tab-tit
		margin-left 155px
.tab-tit
	font-size var(--fz20)
	a
		vertical-align middle
		&:after
			content '/'
			display inline-block
			vertical-align middle
			margin 0 30px
			font-size var(--fz28)
			color #333
		&:last-child:after
			display none
		+hoverPc()
			color m
	.on
		color m
.news-tab
	margin-left auto
	a
		font-size var(--fz30)
		&:last-child
			&:after
				display none
		&:after
			content ''
			border-left 1px solid #C3CAD2
			height 1em
			margin-left 25px
			margin-right 20px
		&.on
			color m
.swiper-news
	.item
		display block
		padding 50px 66px 56px 70px
		border 1px solid li
		height 100%
		margin-right -1PX
		transition background-color .3s, border-color .3s
		&:after
			content ''
			position absolute
			right 0
			bottom 0
			width 90%
			background url("../images/logo-news.png") no-repeat right bottom
			background-size 100%
			height 100%
			opacity 0
			transition .3s
		&:hover
			background-color m
			border-color m
			color #fff
			.text
				color #fff
			h4
				color #fff
			.date
				p
					color #fff
			&:after
				opacity 1
			.more-arrow-link
				color #fff
	.swiper-slide
		height auto
		overflow hidden
	.date
		em
			font-weight bold
			line-height 1
			font-size var(--fz44)
			transition color .3s
		p
			transition color .3s
	h4
		font-size var(--fz24)
		margin-top 26px
		transition color .3s
		margin-bottom 20px
		hiddenFix(2)
	.text
		color #666666
		transition color .3s
		hiddenFix(2,1.75)
		margin-bottom 80px
	.more-arrow-link
		position relative
		z-index 10
.i-row5
	background url("../images/bg-join.jpg") no-repeat 50%
	cover()
	padding-top 140px
	padding-bottom 140px
	.wp
		display flex
	.t3
		width 12em
	.hd
		.t4
		.t3
			color #fff
		.t4
			margin-bottom 26px
	.bd
		margin-left auto
		display flex
.join-card
	width 320px
	height 480px
	padding 50px 30px 30px 30px
	transition .3s
	&:hover
		transform translateY(-20px)
	a
		display block
		height 100%
		position relative
	.t4
		margin-bottom 21px
	.iconfont
		position absolute
		left 0
		bottom 0
		line-height 1
		font-size var(--ic50)
		color #fff
.join-card1
	background url("../images/join-card1.jpg") no-repeat left top
	cover()
.join-card2
	background url("../images/join-card2.jpg") no-repeat left top
	margin-top 40px
	margin-left 60px
	cover()
	h4
	.text
		color #fff
.i-row6
	display flex
	.img
		flex 0 1 50%
		.bg
			height 540px
	.txt
		flex 0 1 50%
		background url("../images/bg2.jpg") no-repeat left top
		cover()
		padding 0 140px
		display flex
		flex-direction column
		justify-content center
	h3
		color #fff
	.btn-row
		margin-top 46px
.bg-video-cover
	position absolute
	left 0
	right 0
	bottom 0
	width 100%
	height 100%
	object-fit cover
+padW()
	.swiper-solution-outer
		.item
			display block
		.intro
			margin-bottom 40px
	.i-row1 .wp-full
		padding-top 100px
		padding-bottom 100px
	.swiper-solution-inner
		width 650px
		margin 0 auto
	.banner-arrow
		display none
	.swiper-solution-outer:after
		top auto
		right 0
		width 100%
		height 450px
		background-size auto 100%
		bottom 150px
+padH()
	.i-row2 .txt
		padding-left 40px
	.swiper-news .item
		padding-left 50px
		padding-right 50px
+mb()
	#banner .swiper-pagination-bullet
		width 52px
		height 52px
		margin 0 14px
	.solution-side
		display none
	.i-row1
		padding-right 0
		.t1
			margin-bottom 60px
	.tab-tit a:after
		font-size 22px
	.i-row1
		.wp-full
			padding-left 5% !important
			padding-right 5% !important
	.swiper-solution-outer
		.intro
			padding-top 18px
			padding-right 0
			h4
				display none
	.swiper-solution-inner .swiper-slide
		padding-bottom 160px
		
	.i-row2
		.wp
			display block
		.txt
			padding-left 0
			padding-top 60px
		.img .bg
			height 440px
		.text
			margin-bottom 44px
		.summary-number-index
			margin-top 70px
			flex-wrap wrap
			justify-content center
			li
				flex 0 1 50%
				text-align center
				padding-bottom 50px
				padding-top 50px
				border 1px solid li
				margin-right -1PX
				margin-bottom -1PX
			li:nth-child(1)
				border-left none
				border-top none
			li:nth-child(2)
				border-right none
				border-top none
			li:nth-child(3)
				border-left none
				border-bottom none
			li:nth-child(4)
				border-right none
				border-bottom none
	.i-row3 .t1
		margin-bottom 30px
	#banner
		height 1080px
	.i-row4
		.hd
			display block
			margin-bottom 30px
		.t1
			position relative
		.tab-tit
			margin-left 0
			margin-top 42px
	.swiper-news
		.item
			background-color m
			border-color m
			color #fff
			padding-top 110px
			padding-bottom 270px
			&:after
				width 100%
			.text
				color #fff
			h4
				color #fff
				margin-top 66px
			.date
				p
					color #fff
					font-size 12px
			&:after
				opacity 1
			.more-arrow-link
				color #fff
		.swiper-pagination-solid
			margin-bottom 60px
			.swiper-pagination-bullet
				opacity .2
			.swiper-pagination-bullet-active
				background-color #fff
				opacity 1
	.i-row5
		background none
		padding-top 0
		padding-bottom 0
		.wp
			display block
			padding-left 0
			padding-right 0
		.hd
			background-image url("../images/bg-join.jpg")
			cover()
			padding-left 40px
			padding-right 40px
			padding-top 130px
			padding-bottom 170px
		.bd
			padding-top 10px
			padding-bottom 10px
	.join-card
		flex 0 1 50%
		height 556px
	.join-card1
		border-right .05rem solid #fff
	.join-card2
		margin-left 0
		margin-top 0
		border-left .05rem solid #fff
	.i-row6
		display block
		.img
			.bg
				height 500px
		.txt
			padding-left 40px
			padding-right 40px
			padding-top 120px
			padding-bottom 120px
	.ban-con 
		.wp
			width 100%
		p
			max-width 9000px
			