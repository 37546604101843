.list-equipment
	margin 0 -12px
	display flex
	flex-wrap wrap
	li
		padding 12px
		flex 0 1 33.3333%
	.img
		padding-top 76.6%
	.txt
		font-size var(--fz20)
		height 110px
		padding 0 20px
		display flex
		flex-direction column
		justify-content center
		text-align center
		h3
			hidden(2)
	.pad
		background-color #F4F6F8
+mb()
	.list-equipment
		display block
		.txt
			height auto
			padding-bottom 56px
			padding-top 50px
			display block
