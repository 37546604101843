.page-404
	display flex
	background-image url("../images/bg-404.jpg")
	cover()
	height 100vh
	flex-direction column
	justify-content center
	align-items center
	text-align center
	h1
		line-height 1.2
		font-size 177px
		color #fff
	p
		color #fff
		font-size var(--fz24)
	.btn-row
		margin-top 100px
		a
			margin 0 6px
			+mb()
				margin-bottom 28px
	