.wp
	width 100%
	max-width 1520px
	margin 0 auto
	+lg()
		width 90%
	+padH()
		width 94%
	+mb()
		width auto
		padding-left 40px
		padding-right 40px
.wp-md
	width 100%
	max-width 1400px
	margin 0 auto
	+padW()
		width 90%
		max-width 2000px
.wp-cn
	width 100%
	max-width 1200px
	margin 0 auto
	+padW()
		width 90%
		max-width 2000px
.wp-sm
	width 100%
	max-width 980px
	margin 0 auto
	+padW()
		width 90%
		max-width 2000px