/*******************************
	-------[=底部订阅]-------
********************************/
.fd-subscription
	width 320px
	.inp
		border none
		font-size var(--fz14)
		width 100%
		background-color #3C3C3C
		height 50px
		padding 0 15px
		vertical-align bottom
		display inline-block
		appearance none
		color rgba(255,255,255,.6)
.form-ele
	border none
	font-size var(--fz18)
	vertical-align bottom
	width 100%
	background-color #F5F7F9
	height 55px
	padding 0 20px
	vertical-align bottom
	display inline-block
	appearance none
	color #666
	border-radius 0
.modal
	.form-ele
		height 60px
	.form-box .msg-box textarea
		height 220px
select.form-ele
	line-height inherit !important
textarea.form-ele
	padding 20px 25px
	line-height 1.8
.form-box
	label
		position absolute
		left 0
		top 0
		padding-left 5px
		margin-left 20px
		line-height 55px
		color #666
		&:after
			content '*'
			color #f00
	.hide-label
		label
			display none
	::placeholder
		color #666
	:-ms-input-placeholder
		color #666
	:-webkit-input-placeholder
		color #666
.form-box
	font-size var(--fz18)
	li
		margin-bottom 20px
		position relative
		line-height 40px
		cf()
	label.error
		color red
		font-size 14px
		position static
		margin-left 0
	.inp-col
		label.error
			line-height 1.3
			padding-left 0
	.inp
		overflow hidden
	.tip-u
		position absolute
		right 0
		bottom 0
		line-height inherit
	.tips
		position absolute
		right 110px
		top 0
		display none
		color #d30000
	.msg-box
		textarea
			height 230px
	.inp-col
		position relative
		// float left
		// width 50%
		flex 0 1 50%
		padding 0 5px
	.inp-row
		display flex
		margin 0 -5px
	select
		height 55px
		padding 0 20px
		font-size var(--fz18)
		color #666
		width 100%
	
.inp-select
	position relative
	select
		padding-right 60px
		width 100%
	&:after
		content '\e728'
		position absolute
		right 0
		top 0
		font-family 'iconfont'
		right 20px
		top 50%
		transform translateY(-50%)
.m-pro-select
	.inp-select
		&:after
			color #fff
.inp-code
	display flex
	width 50%
	.form-ele
		flex 1
	a
		height 55px
		padding-left 10px
	img
		height 100%
.row-code
	display flex
	.inp-row
		flex 0 1 50%
	.more-arrow
		margin-left auto
	.error
		padding-left 20px
+mb()
	.form-box
		li
			margin-bottom 0
		.form-ele
			height 80px
			padding-left 30px
			padding-right 30px
		select
			padding-left 30px
			padding-right 30px
		.inp-code a
			height 80px
		.inp-select:after
			right 30px
		select
			height 80px
		label
			margin-left 30px
			line-height 80px
		.inp-col
			float none
			width auto
			margin-bottom 30px
		.row-code
			margin-top 30px
			display block
		.inp-code
			width auto
		.msg-box textarea
			height 400px